.container-stake {
  padding: 0px 60px;
  padding-bottom: 40px;
}

.stake-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.stake-main-text {
  font-family: KG;
  font-size: 2em;
  max-width: 1000px;
  text-align: center;
}

.stake-step {
  margin: 50px 10px;
}

.stake-step-pic {
  width: 100%;
  max-width: 1200px;
}

.stake-step-title {
  font-family: KG;
  font-size: 2em;
  margin-bottom: 10px;
  text-align: center;
}

.stake-step-text {
  margin-top: 10px;
  padding: 10px;
  font-size: 1.2em;
  text-align: center;
}

@media screen and (max-width: 660px) {
  .container-stake {
    padding: 0px 10px;
  }

  .stake-step-pic {
    width: 100%;
  }
}
