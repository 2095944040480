@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@100;200&display=swap');

@font-face {
  font-family: 'karmatic';
  src: url('./assets/ka1.ttf') format('truetype');
}
@font-face {
  font-family: 'KG';
  src: url('./assets/KGHAPPY.ttf') format('truetype');
}

body {
  font-family: Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue',
    sans-serif;
  background-color: #140c34;
  color: white;
}

.pink {
  color: #da9af6;
}

.header-top-wrapper {
  display: flex;
  justify-content: center;
  /* background-color: #5e00ff; */
  /* -webkit-box-shadow: 0px 5px 12px 3px rgba(0, 0, 0, 0.55);
  box-shadow: 0px 5px 12px 3px rgba(0, 0, 0, 0.55); */
}

.header-wrapper {
  max-width: 1300px;
  color: white;
}

.countdown-wrapper {
  display: flex;
  justify-content: center;
  font-weight: 900;
  font-size: 2.5em;
}

.app-wrapper {
  padding: 60px 15%;
  padding-top: 0px;
}

.main-title {
  margin-bottom: 20px;
  font-size: 2.5em;
  line-height: 1.5;
  font-family: KG;
}
.sub-title {
  font-size: 20px;
  margin: 20px 0px;
  line-height: 1.5;
}
.bigger {
  font-size: 4em;
}
.centered {
  text-align: center;
}

.header-pic-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.header-pic {
  margin-top: 20px;
  margin-bottom: 20px;
  width: 100%;
  max-width: 500px;
}

.header-text-wrapper {
  margin-top: 20px;
  padding: 20px;
}

.mint-button-wrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.sub-text {
  font-size: 1.2em;
  text-align: center;
}

.main-mint-btn {
  background-color: white !important;
  color: #da9af6 !important;
  margin: 40px 0px !important;
  height: 60px;
  width: 100% !important;
  padding: 10px 40px;
  font-size: 1.5em;
  font-weight: 800;
  letter-spacing: 2px;
  border-radius: 10px;
  border: none;
  height: auto;
}

.btn {
  background-color: #cc7cee !important;
  color: white !important;
  margin: 40px;
  height: 60px;
  width: auto;
  padding: 10px 40px;
  font-size: 1.5em;
  font-weight: 800;
  letter-spacing: 2px;
  border-radius: 10px;
  border: none;
  height: auto;
}
.btn:hover {
  background-color: hsl(282, 79%, 63%) !important;
}

.market-btn {
  background-color: white !important;
}

.trade-btn {
}
.trade-btn img {
  height: 35px;
  margin-left: 10px;
}

.market-btn img {
  height: 35px;
}

.main-mint-btn:hover {
  background-color: #da9af6 !important;
  color: white !important;
}

.main-mint-btn.approved,
.main-mint-btn.approved:hover,
.main-mint-btn.approved:focus {
  background-color: white !important;
  color: #2dddb4 !important;
}

.main-mint-btn:disabled {
  background-color: white !important;
  cursor: not-allowed;
  pointer-events: all !important;
  color: #da9af6 !important;
}

.faq-wrapper {
  margin-top: 60px;
}

.faq-wrapper ul li {
  list-style-type: none;
  font-size: 1.15em;
  margin: 30px 0px;
}

.faq-wrapper ul li .question {
  color: #da9af6;
  font-weight: 600;
  font-size: 1.3em;
  margin-bottom: 10px;
}

.banner-wrapper {
  height: auto;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 25px;
}

.banner-wrapper .logo-link {
  text-decoration: none;
  display: flex;
}

.banner-wrapper .logo-wrapper {
  display: flex;
  color: #5e00ff;
  align-items: center;
  margin-left: 20px;
}

.banner-wrapper .logo {
  height: 60px;
}

.banner-wrapper .logo-text {
  font-size: 69px;
  font-weight: 200;
  font-family: KG, Open-sans;
  color: #da9af6;
  letter-spacing: 5px;
  text-align: center;
}

.banner-wrapper .links {
  display: flex;
  align-items: center;
  color: white;
}

.banner-wrapper .links .btn-link {
  color: white;
}

@media screen and (max-width: 660px) {
  .banner-wrapper .logo-link {
    justify-content: center;
    margin: 25px;
  }

  .banner-wrapper {
    flex-direction: column;
    height: auto;
  }
  .banner-wrapper .links {
    flex-direction: column;
  }

  .banner-wrapper .logo-text {
    font-size: 2.3em;
  }

  .wallet {
    flex-direction: column;
  }

  .wallet > div,
  .wallet > button {
    margin-right: 0px;
    margin-top: 10px;
  }
}

.links .btn-link {
  font-size: 1.2em;
}

.links .btn-link i {
  margin-right: 5px;
}

.footer-wrapper {
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 60px;
}

.footer-wrapper .links .btn-link {
  color: white;
}

.wallet-wrapper {
  display: flex;
  justify-content: center;
  margin: 20px 0px;
}

.wallet {
  display: flex;
}

.wallet > div,
.wallet > button {
  margin-right: 10px;
}

.banner {
  /* background-image: url('./assets/banner.png'); */
  height: 300px;
  width: 100vw;
  /* -webkit-box-shadow: 0px 5px 12px 3px rgba(0, 0, 0, 0.55);
  box-shadow: 0px 5px 12px 3px rgba(0, 0, 0, 0.55); */
  margin-top: -60px;
  background-size: 2029px;
  background-attachment: fixed;
}

.banner.fixed {
  margin-top: 0px;
  background-attachment: initial;
}

.team-section {
  background-color: #07031a;
  color: white;
  text-align: center;
  padding-top: 20px;
}

.section-title {
  color: white;
  font-size: 40px;
}

.team-wrapper {
  background-color: #07031a;
  padding: 30px;
  padding-top: 0px;
  display: flex;
  justify-content: center;
  align-items: baseline;
  flex-wrap: wrap;
}

.team-wrapper .member .team-pic {
  height: 153px;
  width: 150px;
  border-radius: 7%;
}

.team-wrapper .member {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 20px 30px;
  flex-basis: 155px;
  text-align: center;
  flex-grow: 1;
}

.team-name {
  color: white;
  font-weight: bolder;
  font-size: 24px;
}
.team-title {
  color: white;
  font-weight: normal;
  font-size: 20px;
}

.subheader-wrapper {
  display: flex;
  justify-content: center;
  padding: 50px;
  background-color: #07031a;
  align-items: center;
}

.subheader-wrapper .percent {
  font-size: 5em;
  font-family: KG;
  width: 160px;
  flex-basis: 160px;
}

.subheader-text {
  font-size: 1.7em;
  padding-left: 40px;
}

.subheader-text .pink {
  font-weight: bold;
}

.fakeLink {
  text-decoration: underline;
  cursor: pointer;
}

.token-wrapper {
}

.token-panels-wrapper {
  display: flex;
  justify-content: center;
}

.token-panel {
  width: 30%;
  max-width: 400px;
  background-color: #07031a;
  padding: 20px;
  margin: 20px;
  border-radius: 14px;
  text-align: center;
  display: flex;
  flex-direction: column;
}

.token-panel-title {
  color: #da9af6;
  font-family: KG;
  text-align: center;
  font-size: 2em;
  margin-bottom: 15px;
}

.token-panel-text {
  font-size: 20px;
  text-align: center;
}

.chart-wrapper {
  display: flex;
  justify-content: center;
  margin: 20px 5px;
}

.chart-wrapper img {
  max-width: 1400px;
  width: 100%;
}

.token-counter-wrapper {
  display: flex;
  justify-content: center;
  padding: 50px;
  background-color: #07031a;
  align-items: center;
  flex-direction: column;
}

.token-counter-wrapper .counter {
  font-size: 4em;
  font-family: KG;
  text-align: center;
  word-break: break-all;
}

@media screen and (max-width: 660px) {
  .token-panels-wrapper {
    flex-direction: column;
    align-items: center;
  }

  .token-panel {
    width: 80%;
  }

  .token-counter-wrapper .counter {
    font-size: 2em;
  }
}

.popover-content {
  background-color: white;
  padding: 10px;
  color: #07031a;
  border-radius: 14px;
}

a {
  color: white;
}
